<div class="ticket-wrapper">
  <div class="ticket-info-wrapper">
    <div class="ticket-nomecoda-wrapper">Coda: <span class="ticket-nomecoda">{{nome_coda}}</span></div>
    <div class="ticket-codice-wrapper">Il tuo turno: <span class="ticket-codice">{{codice}}</span></div>
    <div class="ticket-orario-wrapper">Passi alle <span class="ticket-orario">{{orario}}</span></div>
    
  </div>
  <div><button class="ticket-annulla-prenotazione" (click)="mostraConferma()">ANNULLA</button></div>
  <div class="lightbox-conferma-elimina" [ngStyle]="{'display': mostra_conferma}">
    <div class="conferma-elimina">
      <h4>Annullo la prenotazione {{codice}} alle {{orario}}?</h4>
      <div class="buttons-wrapper">
        <button class="button-scelta-sino button-si" (click)="elimina()">Sì</button>
        <button class="button-scelta-sino button-no" (click)="nascondiConferma()">No</button>
      </div>
    </div>
  </div>
</div>
<!-- IMPORTANTE PER IL MARGIN BOTTOM -->
<br>
