import { Injectable } from '@angular/core';
import { FirebaseAuthService } from '../firebase/auth/firebase-auth.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  
  private loggedIn: boolean;

  public previousUrl;

  constructor(private faService: FirebaseAuthService,
              private afs: AngularFirestore) { }

  public getAfs(): Promise<AngularFirestore> {

    // Lucio, 2020-11-29:
    // Now I know this ugly sh!t is not going to make anything more secure, but,
    // back when we designed the whole thing, we made a horrible design flaw:
    // app end users are NOT supposed to sign up nor to sign in, but they ARE
    // supposed to create tickets into the database. Changing that decision now
    // seems to be out of the possible choices, because sales department
    // asked for it in the first place (and we overlooked the risks).
    // In my mind, we let them launch the ship and hope for the best during the
    // initial phase, where few users exist and this app is not
    // very widespread, and then, if this app keeps going, we'll make the 2.0 
    // release which will require end users to sing up and sign in, with forced
    // update for 1.0 users.
    // However, as of this writing, I only have two choices: either I grant
    // anonymous users read and write access, or I grant this particular user
    // only read and write access. The latter, at least, forces random script
    // kiddies to go for something else.

    return this.loginIfNeeded(window.atob('Y2xvdWRuZXh0LWFwcEB0ZW9tLml0'), 
    window.atob('Tm9fbWF0dGVyX2hvd19sb25nX3RoaXNfcGFzc3dvcmRfaXNfYmVjYXVzZV9pdF93aWxsX2xpdmVfaW5fY2xlYXJ0ZXh0X2ludG9fdGhlX2FwcF9hbnl3YXk'));
  }

  private loginIfNeeded(email: string, password: string): Promise<AngularFirestore> {

    const result = new Promise<AngularFirestore>((resolve, errmanager) => {
      if (!this.loggedIn) {
    
        this.faService.signInWithEmail(email, password).
            then(res => {
              this.loggedIn = true;
              resolve(this.afs);
            }, 
            rej => {
              errmanager('no access');
            });
        } else {
          resolve(this.afs);
        }
    
    });
    return result;
  }

  public login(email: string, password: string): Promise<AngularFirestore> {
    this.loggedIn = false;
    return this.loginIfNeeded(email, password);
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

    /** 
   * Initiate the password reset process for this user 
   * @param email email of the user 
   */ 
  public initPasswordReset(email: string): Promise<void> {
    let portpart = "";

    if (typeof window.location.port !== 'undefined' && window.location.port) {
      portpart = ":" + window.location.port;
    };

    const returnToUrl = window.location.protocol + '//' + window.location.hostname + portpart + '/auth/login';
    return this.faService.angularFire.sendPasswordResetEmail(
        email, 
        { url: returnToUrl}
        ); 
  } 

}