import { Injectable } from '@angular/core';
import { OrariCodaService, OrarioCoda } from './orari-coda.service';
import { BusinessIdService } from './business-id.service';
import { CodaEsercizioService, CodaEsercizio } from './coda-esercizio.service';
import { PrenotazioneCodaService, Ticket } from './prenotazione-coda.service';
import { Observable } from 'rxjs';
import { GiornoAttualeService } from './giorno-attuale.service';
import { EsercizioCommerciale, EsercizioCommercialeService } from './esercizio-commerciale.service';

export class Slot {
  disponibile: boolean;
  orario: number;
  codice: string;
}

@Injectable({
  providedIn: 'root'
})
export class SlotDisponibiliService {
  private mappaSlot: Map<string, Observable<Slot[]>> = new Map();

  constructor(private ceService: CodaEsercizioService,
              private ocService: OrariCodaService,
              private bidService: BusinessIdService,
              private pcService: PrenotazioneCodaService,
              private ecService: EsercizioCommercialeService) { }

  private generateSlots(observer, idCoda: string, dayIndex: number, durata_slot_default: number = 30) {
    const tempresult: Slot[] = Array<Slot>();
    this.ceService.get(this.bidService.get(), idCoda).subscribe(
      (codaFB) => {
        const coda: CodaEsercizio = new CodaEsercizio();
        Object.assign(coda, codaFB);
        this.ocService.getList(this.bidService.get(), idCoda).then(
          (orariFB) => {
            orariFB.forEach((orarioFB, idx) => {
              const orarioCoda: OrarioCoda = new OrarioCoda();
              const srcobj = orarioFB.payload.doc.data();
              Object.assign(orarioCoda, srcobj);
              if (parseInt(orarioCoda.id_giorno, 10) === dayIndex) {
                const inizio = new Date();
                inizio.setHours(orarioCoda.ora_apertura.hour);
                inizio.setMinutes(orarioCoda.ora_apertura.minute);
                inizio.setSeconds(0); // tronco al minuto inferiore
                inizio.setMilliseconds(0);// tronco al secondo inferiore
                let inizioMillis = inizio.getTime();
                inizioMillis = inizioMillis - (inizioMillis % (1000 * 60)); // tronco al minuto e secondo inferiore
                const inizioMillisNoGiorno = inizioMillis % 86400000;
                const inizioGiornoNoMillis = inizioMillis - inizioMillisNoGiorno;

                const fine = new Date();
                fine.setHours(orarioCoda.ora_chiusura.hour);
                fine.setMinutes(orarioCoda.ora_chiusura.minute);
                fine.setSeconds(0); // tronco al minuto inferiore
                fine.setMilliseconds(0); // tronco al secondo inferiore
                let fineMillis = fine.getTime();
                fineMillis = fineMillis - (fineMillis % (1000 * 60)); // tronco al minuto e secondo inferiore
                const fineMillisNoGiorno = fineMillis % 86400000;

                const adesso = new Date().getTime();

                coda.durata_slot = coda.durata_slot ?? durata_slot_default;

                if (coda.durata_slot === 0) {
                  coda.durata_slot = 1;
                }

                // creo gli slot (o li riprendo da quelli che mi sono stati passati come argomento di questa funzione)
                for (let slot = inizioMillisNoGiorno; slot < fineMillisNoGiorno; slot += (coda.durata_slot * 60 * 1000)) {
                  if (adesso > slot + inizioGiornoNoMillis) {
                    continue;
                  }
                  const orarioSlot = slot + inizioGiornoNoMillis;
                  const nuovoSlot = new Slot();
                  nuovoSlot.codice = this.codicePrenotazione(slot, idCoda);
                  nuovoSlot.orario = orarioSlot;
                  nuovoSlot.disponibile = true;
                  tempresult.push(nuovoSlot);
                }

                // ora in tempresult ho l'elenco degli slot "teorici" di oggi. Da questo elenco devo
                // "togliere" (disponibile=false) gli slot per cui esiste già una prenotazione.
                // Non voglio però fare questa operazione una tantum, ma voglio ripeterla ogni volta
                // che mi arriva una notifica di elenco prenotazioni aggiornato e devo riflettere
                // questa rimozione al chiamante, in modo che possa aggiornare la sua view.
                // Mi iscrivo quindi alle notifiche che arrivano dal db, faccio tutta l'elaborazione
                // dell'array degli slot disponibili ed inoltro la notifica all'observer del chiamante.

                this.pcService.getList(this.bidService.get(), idCoda, new Date().getTime())
                  .subscribe(values => {
                    // passo 1: creo un nuovo array di slot che conterrà l'elenco ripulito
                    // passo 2: per ogni prenotazione ricevuta dal database, elimino (disponibile = false)
                    // il rispettivo slot
                    const cleanSlots: Slot[] = tempresult.map((tslot) => {
                      if (values.some(ticket => {
                            const ticketMillis = ticket.getMillis();
                            const tSlotMillis = tslot.orario;
                            return ticketMillis === tSlotMillis;
                          })) {
                        tslot.disponibile = false;
                      }
                      return tslot;
                    });

                    cleanSlots.sort((slotA: Slot, slotB: Slot) => {
                      return slotA.orario - slotB.orario;
                    });

                    observer.next(cleanSlots);
                  });
              }
            });
          },
          (failedReason) => {observer.error(failedReason);}
        );
      },
      (failure) => { observer.error(failure); }
    );
  }


  private esistePrenotazione(idCoda: string, slot: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.pcService.get(this.bidService.get(), idCoda, slot).then(
        (pcresolve) => {
          resolve(true);
        },
        (pcreject) => {
          resolve(false);
        }
      );
    });
  }

  get(idCoda: string = null): Observable<Slot[]> {
    if (idCoda === null) {
      idCoda = this.bidService.getCodaAttuale().uid;
    }
    let oresult: Observable<Slot[]> = null;
    if (this.mappaSlot.has(idCoda)) {
      oresult = this.mappaSlot.get(idCoda);
    } else {
      oresult = new Observable<Slot[]>((observer) => {
        this.generateSlots(observer, idCoda, GiornoAttualeService.currentDay());
      });
      this.mappaSlot.set(idCoda, oresult);
    }

    return oresult;
  }

  public codicePrenotazione(orarioMilli: number, idCoda: string): string {
    const millisecondiInUnGiorno = 86400000;
    const soloOrarioInMilli = orarioMilli % millisecondiInUnGiorno;
    const soloIlGiornoDal1970 = (orarioMilli - soloOrarioInMilli) / millisecondiInUnGiorno;
    let numeroDelMinutoNelGiorno = Math.round(soloOrarioInMilli / 1000 / 60);

    const lettere = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    let numeroDelGiornoModulato =
      (soloIlGiornoDal1970 * soloIlGiornoDal1970) % 23;
    // L'intenzione del calcolo qui sopra è rendere casuale il primo codice disponibile per ogni giorno,
    // in modo che gli utenti non si abituino al fatto che alle 10 il codice è sempre "BD" o altro.
    // Serve ad evitare assunzioni errate da parte degli utenti e costringerli ad usare la app ogni volta
    // per sapere il codice corretto ad un determinato orario: in particolare se l'utente "furbo" si
    // salva la schermata del cellulare sperando di poterla riutilizzare come prenotazione perenne
    // per gli anni a venire, resterà deluso, perché non saprà mai quando quel codice capiterà
    // di nuovo a quell'orario.
    // Il calcolo però deve essere solo pseudo-casuale (ovvero deve essere deterministico, 
    // ma non deve apparire tale), perché questi codici non vengono salvati da nessuna parte, ma solo 
    // rigenerati ogni volta che serve visualizzarli. È quindi necessario che il giorno X all'ora Y ci 
    // sia un codice apparentemente casuale, ma invariabile ogni volta che lo si calcola.
    // Per questo motivo ho usato la funzione di distribuzione pseudo casuale classica delle hashmap, ovvero
    //    pseudorand(i) = i² mod S
    // dove S è un numero primo e coincide con la dimensione della hashmap.
    // Nel nostro caso, dato che l'array delle lettere prende il posto della hashmap e dato che, una volta
    // identificato il punto pseudo-casuale di partenza poi lo si scorre in ordine, non è così importante
    // che il numero S coindida esattamente con la dimensione dell'array, ma ci basta anche se è un numero
    // primo di poco inferiore alla dimensione.
    // 23 è il numero primo più vicino al numero di lettere dell'alfabeto inglese, e l'alfabeto inglese
    // è l'insieme di valori da cui voglio pescarne uno in modo pseudocasuale. Essendo le lettere 26, usare
    // 23 significa che le ultime 3 non saranno mai scelte come prima lettera del primo codice della giornata,
    // ma, tutto sommato, come detto sopra, va bene ugualmente. L'alternativa sarebbe usare 29, e poi
    // ripetere l'operazione di modulo sul risultato per assicurarsi che non vada oltre 25, ma questo
    // avrebbe un effetto altrettanto impreciso sulla distribuzione, favorendo in quel caso le prime
    // lettere.
    // A questo punto numeroDelGioroModulato vale fra 0 e 22 e si distribuisce pseudocasualmente e
    // più o meno equamente in quell'intervallo di giorno in giorno.
    // Gli sommo ancora un numero deterministico calcolato a partire dalla stringa idCoda, in modo che
    // due ticket nello stesso orario ma su code diverse non risultino uguali. Il risultato lo prendo
    // poi modulo 26.
    // Quel che resta lo uso come "salt", in modo che ad un determinato orario di prenotazione, in 
    // giorni diversi o code diverse, non corrisponda sempre lo stesso codice di ticket.
    numeroDelGiornoModulato = (numeroDelGiornoModulato + this.saltIdCoda(idCoda)) % lettere.length;
    numeroDelMinutoNelGiorno += numeroDelGiornoModulato;

    // ora ho un numero di minuto all'interno della giornata da usare per calcolare il codice di due lettere
    // da assegnare al ticket.
    const massimoNumeroDiCombinazioni = 
      lettere.length * lettere.length; // perché il codice ticket è formato da 2 lettere.
                                        // 676 combinazioni, 1 al minuto = circa 11 ore di codici
    numeroDelMinutoNelGiorno =
      numeroDelMinutoNelGiorno % massimoNumeroDiCombinazioni; // così per gli orari dopo le prime 11 ore circa riparte da
                                                    // "AA", o meglio da quello che era il primo codice pseudocasuale di oggi
    const indiceSecondaLettera = numeroDelMinutoNelGiorno % lettere.length;
    const indicePrimaLettera = (numeroDelMinutoNelGiorno - indiceSecondaLettera) / lettere.length;
    const codice = lettere[indicePrimaLettera] + lettere[indiceSecondaLettera];
    return codice;
  }

  private saltIdCoda(idCoda: string): number {
    let result = 0;
    for (let i = 0; i < idCoda.length; i++) {
      result ^= idCoda.charCodeAt(i);      
    }
    return result;
  }
}
