import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { formatDate } from '@angular/common';
import { Ticket } from '../../api/prenotazione-coda.service';
import { DeviceIdService } from '../../api/device-id.service';
import { Slot } from '../../api/slot-disponibili.service';
import { EsercizioCommercialeService } from '../../api/esercizio-commerciale.service';
import { BusinessIdService } from '../../api/business-id.service';
import { EsercizioCommerciale } from '../../api/esercizio-commerciale.service';
import { Observable } from 'rxjs';

registerLocaleData(localeIT, 'it');

@Component({
  selector: 'app-slot-prenotabile',
  templateUrl: './slot-prenotabile.component.html',
  styleUrls: ['./slot-prenotabile.component.scss'],
})
export class SlotPrenotabileComponent implements OnInit {

  @Input() slot: Slot;

  @Output() prenota = new EventEmitter();

  etichetta: string;
  classe: string;
  etichetta_codice: string;
  testo_orario: string;
  etichetta_tasto_riga1: string;
  etichetta_tasto_riga2: string;
  slot_disponibile: boolean;
  css_non_disponibile: string;

  disponibilita_disponibile = false;
  esercente_aperto = true;


  constructor(private didService: DeviceIdService,
    private ecService: EsercizioCommercialeService,
    private bidService: BusinessIdService) { }

  ngOnInit() {

    this.getEtichettaTasto();
    this.aggiornaProperties();
    this.testo_orario = formatDate(this.slot.orario, 'HH:mm', 'it');
    this.disponibilita_disponibile = this.slot.disponibile;
    if (this.slot.disponibile === true) {
        this.etichetta = 'Arriverò alle ';
        this.etichetta_codice = 'Passerò con il codice ';
    } else {
        this.etichetta = 'Orario già occupato: ';
        this.etichetta_codice = 'Codice ';
    }
    this.aggiornaProperties();   
    
    const now = Date.now();
    const intervallo_disabilitato = 60000 * 10; // i ticket fino a 10 minuti (che dovrebbero poi diventare configurabili)
                                                //  da adesso non sono prenotabili
    if (this.slot.orario <= (now + intervallo_disabilitato)) {
      this.slot.disponibile = false;
    }
    else
    {
      const scadenzaSlot = this.slot.orario - Date.now() - intervallo_disabilitato; // diventa non più prenotabile 10 minuti prima del suo orario effettivo
      window.setTimeout(() => { 
        this.slot.disponibile = false; 
      }, scadenzaSlot);
    }
  }

  getEtichettaTasto() {
    this.ecService.getEsercizioCommerciale(this.bidService.get()).subscribe(
      (value) => {
        const ec: EsercizioCommerciale = new EsercizioCommerciale();
        Object.assign(ec, value);
        this.esercente_aperto = ec.prenotazioniAttive;
        this.aggiornaProperties();
      }
    );
  }

  aggiornaProperties() {
    if (this.esercente_aperto) {
      if (this.disponibilita_disponibile) {
        this.classe = 'slot-prenotabile-disponibile';
        this.slot_disponibile = true;
        this.css_non_disponibile = 'display: none;';
      } else {
        this.classe = 'slot-prenotabile-occupato';
        this.slot_disponibile = false;
        this.css_non_disponibile = '';
      }
      this.etichetta_tasto_riga1 = 'Prendi';
      this.etichetta_tasto_riga2 = 'ticket';
    } else {
      this.etichetta_tasto_riga1 = 'Esercente';
      this.etichetta_tasto_riga2 = 'chiuso';
      this.classe = 'slot-prenotabile-occupato';
      this.slot_disponibile = false;
    }
  }

  prenotaClicked() {
    const ticket: Ticket = new Ticket();
    ticket.setMillis(this.slot.orario);
    ticket.codice = this.slot.codice;
    ticket.device_id = this.didService.get();
    this.prenota.emit(ticket);
  }

}
