<div class="slot-prenotabile-wrapper elenco-prenotazioni">
  <div class="slot-prenotabile-info-wrapper">
    <!-- <div [class]="classe">{{etichetta}}</div> -->
    <div class="slot-prenotabile-orario"><span>Ore: </span><span [class]="classe">{{testo_orario}}</span></div>
    <div class="slot-prenotabile-codice"><span>Codice: </span><span [class]="classe">{{slot.codice}}</span></div>
  </div> 
  <div classe="prendi-ticket">
    <button class="slot-prenotabile-prendi" [disabled]="!slot.disponibile || !esercente_aperto" (click)="prenotaClicked();"><span class="button-acapo">{{etichetta_tasto_riga1}}</span><span class="button-acapo">{{etichetta_tasto_riga2}}</span></button>
    <div class="non-disponibile" [style]="css_non_disponibile">Non disponibile</div>
  </div>
</div>
