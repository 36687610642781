import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { Observer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GiornoAttualeService {

  constructor() { }

  public static currentDay(): number {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = formatDate(new Date().getTime(), 'EEE', 'en-US');
    const dayIndex = days.indexOf(today);
    return dayIndex;
  }

  private static pushCurrentDay(observer: Observer<number>) {
    observer.next(GiornoAttualeService.currentDay());

    const adesso = new Date().getTime();
    const prossimaMezzanotte = 864001000 - (adesso % 86400000);
    setTimeout(() => {
      GiornoAttualeService.pushCurrentDay(observer);
    }, prossimaMezzanotte);
  }

  public currentObservableDay(): Observable<number> {

    const result = new Observable<number>((observer: Observer<number>) => {
      GiornoAttualeService.pushCurrentDay(observer);
    });
    return result;
  }
}
