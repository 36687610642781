import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';

registerLocaleData(localeIT, 'it');

export class SerializableTicket {
  private milliseconds: number;
  private dataora: Date;

  setMillis(millis: number): void {
    this.dataora = new Date(millis);
    this.milliseconds = millis;
  }

  setDate(date: Date): void {
    this.dataora = date;
    this.milliseconds = date.getTime();
  }

  getMillis(): number {
    return this.milliseconds;
  }

  getDate(): Date {
    return this.dataora;
  }
}

export class Ticket extends SerializableTicket {
  id: string; // chiave primaria
  codice: string; // AA, AB, AC ... è quello mostrato su next
  device_id: string;
}

export class TicketRef extends SerializableTicket {
  idEsercizio: string;
  idCoda: string;
  idPrenotazione: string;
  ticketId: string;
  codice: string;

  getTicketId(): string {
    return this.ticketId;
  }

  setTicketId(id: string): void {
    this.ticketId = id;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrenotazioneCodaService {

  constructor(private afs: AngularFirestore) { }

  public static  toDate (time: Ticket | number): Date {
    let millis: Date;
    if (typeof time === 'number') {
      millis = new Date(time);
    } else {
      millis = time.getDate();
    }
    return millis;
  }

  public static getFlatDate(prenotazione: Ticket | number): string {
    return formatDate(PrenotazioneCodaService.toDate(prenotazione), 'yyyyMMdd', 'it');
  }

  public static getLSPK(idCoda: string, prenotazione: Ticket | number): string { // get Local Storage Primary Key (per questo ticket)
    return idCoda + '_' + PrenotazioneCodaService.getFlatDate(prenotazione);
  }

  public static getFirebasePK(prenotazione: Ticket | number): string {
    return PrenotazioneCodaService.getFlatDate(prenotazione) + '_' + formatDate(PrenotazioneCodaService.toDate(prenotazione), 'HHmm', 'it');
  }

  private getFirebasePath(idEsercizio: string, idCoda: string, prenotazione: Ticket | number): string {
    const dataYYYYMMDD: string = PrenotazioneCodaService.getFlatDate(prenotazione);
    return 'users/' + idEsercizio + '/coda/' + idCoda + '/prenotazioni/' + dataYYYYMMDD + '/ticket/';
  }

  public create(idEsercizio: string, idCoda: string, prenotazione: Ticket): Promise<TicketRef>  {
    const localStoragePKey: string = PrenotazioneCodaService.getLSPK(idCoda, prenotazione);

    if (localStorage.getItem(localStoragePKey) === null) {
      const resultPayload: TicketRef = new TicketRef();
      const result = new Promise<TicketRef>((resolve, reject) => {
        const docRef = this.afs.collection(this.getFirebasePath(idEsercizio, idCoda, prenotazione))
                       .doc(PrenotazioneCodaService.getFirebasePK(prenotazione)).ref;
        this.afs.firestore.runTransaction((tx) => {
          return tx.get(docRef)
            .then((document) => {
                if (document.exists) {
                  throw 'Ticket non più disponibile';
                }
                tx.set(docRef, {...prenotazione});

                resultPayload.idEsercizio = idEsercizio;
                resultPayload.idCoda = idCoda;
                resultPayload.idPrenotazione = PrenotazioneCodaService.getFlatDate(prenotazione);
                resultPayload.setTicketId(PrenotazioneCodaService.getFirebasePK(prenotazione) + '.' + resultPayload.idCoda);
                resultPayload.codice = prenotazione.codice;
                resultPayload.setMillis(prenotazione.getMillis());
                resolve(resultPayload);
                })
            .catch();
        })
        .then()
        .catch(() => { reject("Ticket non più disponibile")});
      });
      return result;
    }
    return new Promise((resolve, reject) => { reject('Hai già una prenotazione per questa coda'); });
  }

  public delete(idEsercizio: string, idCoda: string, prenotazione: number): Promise<void> {
    const ppk =  PrenotazioneCodaService.getFirebasePK(prenotazione);
    const path = this.getFirebasePath(idEsercizio, idCoda, prenotazione);
    return this.afs.collection(path).doc(ppk).delete();
  }

  public get(idEsercizio: string, idCoda: string, orario: number): Promise<Ticket> {
    const pkPrenotazione = PrenotazioneCodaService.getFlatDate(orario);
    return new Promise<Ticket>((resolve, reject) => {
      const path: string =
        'users/' + idEsercizio + '/coda/' + idCoda + '/prenotazioni/' + pkPrenotazione + '/ticket/'
           + PrenotazioneCodaService.getFirebasePK(orario);
      this.afs.doc<any>(path).valueChanges().
          subscribe(data => {
            if (typeof data !== 'undefined') {
              resolve(data);
            } else {
              reject('Ticket non trovato nella coda ' + idCoda + ' dell\'esercizio ' + idEsercizio);
            }
          });
        }
    );
  }

  public getList(idEsercizio: string, idCoda: string, dataoggi: number): Observable<Ticket[]> {
    const pkPrenotazione = PrenotazioneCodaService.getFlatDate(dataoggi);
    const path: string =
    'users/' + idEsercizio + '/coda/' + idCoda + '/prenotazioni/' + pkPrenotazione + '/ticket';
    const result = new Observable<Ticket[]>(observer => {
      this.afs.collection<any>(path).snapshotChanges().subscribe((values) => {
        const result_payload: Ticket[] = [];
        values.forEach((ticket, index) => {
          const tmpobj: Ticket = new Ticket();
          const srcobj = ticket.payload.doc.data();
          Object.assign(tmpobj, srcobj);
          tmpobj.id = ticket.payload.doc.id;
          tmpobj.setMillis(srcobj.dataora.toMillis());
          result_payload.push(tmpobj);
        });
        observer.next(result_payload);
      });
    });
    return result;
  }
}
