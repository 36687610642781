import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EsercizioCommercialeService, EsercizioCommerciale } from '../../api/esercizio-commerciale.service';
import { BusinessIdService } from '../../api/business-id.service';


@Component({
  selector: 'app-denominazione-esercizio',
  templateUrl: './denominazione-esercizio.component.html',
  styleUrls: ['./denominazione-esercizio.component.scss']
})
export class DenominazioneEsercizioComponent implements OnInit {

  private nTaps = 0;
  private timer = null;
  private lastX: number;

  @Output() gestoPerAccessoEsercente = new EventEmitter();  

  css_logo: string;
  constructor(private ecService: EsercizioCommercialeService,
              private bidService: BusinessIdService) { }

  ngOnInit() {
     this.ecService.getEsercizioCommerciale(this.bidService.get())
     .subscribe((value) => {
         const tmpobj: EsercizioCommerciale = new EsercizioCommerciale();
         Object.assign(tmpobj, value);
         tmpobj.id = value.id;
         this.css_logo = 'background-image: url(' + tmpobj.logoHref + ');';
         if (tmpobj.logo_css_style) {
           this.css_logo = this.css_logo + tmpobj.logo_css_style;
         }
       }
     ,
         (failedReason: any) => {});
      
  }

  public tap(event) {
    
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.nTaps > 0) {
      const previousSide = this.lastX < (window.innerWidth / 2);
      const currentSide = event.clientX < (window.innerWidth / 2);
      if (previousSide == currentSide) {
        this.nTaps = 0;
        this.lastX = event.clientX;
      }
    }

    this.lastX = event.clientX;

    this.nTaps++;
    if (this.nTaps >= 7) {
      this.nTaps = 0;
      this.gestoPerAccessoEsercente.emit();
    } else {
      this.timer = setTimeout(() => {
        this.nTaps = 0;
        this.timer = null;
      }, 500);
    }
  }
}
