import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TicketRef, PrenotazioneCodaService } from '../../api/prenotazione-coda.service';
import { CodaEsercizioService, CodaEsercizio } from '../../api/coda-esercizio.service';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { formatDate } from '@angular/common';
import { DeviceIdService } from '../../api/device-id.service';

registerLocaleData(localeIT, 'it');

@Component({
  selector: 'app-ticket-prenotato',
  templateUrl: './ticket-prenotato.component.html',
  styleUrls: ['./ticket-prenotato.component.scss'],
})
export class TicketPrenotatoComponent implements OnInit {


  @Input() ticket_ref: TicketRef;

  @Output() eliminaTicket = new EventEmitter();

  nome_coda: string;
  codice: string;
  orario: string;
  mostra_conferma: string;

  constructor(
    private ceService: CodaEsercizioService,
    private pcService: PrenotazioneCodaService,
    private didService: DeviceIdService

  ) { }

  ngOnInit() {
    this.ceService.get(this.ticket_ref.idEsercizio, this.ticket_ref.idCoda).subscribe(
      (value) => {
        const coda: CodaEsercizio = new CodaEsercizio();
        Object.assign(coda, value);
        this.nome_coda = coda.name.toUpperCase();
        // if (coda.name.length < 15){
        // this.nome_coda = coda.name.toUpperCase();
        // } else {
        //   coda.name = coda.name.slice(0,10) + "...";
        //   // coda.name = coda.name.fontsize(3);
        //   this.nome_coda = coda.name.toUpperCase();
        // }
      },
      (reject) => {}
    );

    this.codice = this.ticket_ref.codice;
    this.orario = formatDate(this.ticket_ref.getMillis(), 'HH:mm', 'it');
    this.nascondiConferma();

  }

  mostraConferma() {
    this.mostra_conferma = 'block';

  }

  nascondiConferma() {
    this.mostra_conferma = 'none';

  }

  elimina() {
    this.pcService.delete(this.ticket_ref.idEsercizio, this.ticket_ref.idCoda, this.ticket_ref.getMillis()).then(
      (resolve) => {
        console.log(resolve);
        window.location.reload();
      },
      (reject) => {
        console.log(reject);
      }
    );
    this.didService.removeTicket(this.ticket_ref).then(
      (resolve) => {
        console.log(resolve);
        window.location.reload();
      },
      (reject) => {
        console.log(reject);
      }
    );
  }

}
