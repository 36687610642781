import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { CodaEsercizioComponent } from './coda-esercizio/coda-esercizio.component';
import { TicketPrenotatoComponent } from './ticket-prenotato/ticket-prenotato.component';
import { SlotPrenotabileComponent } from './slot-prenotabile/slot-prenotabile.component';
import { DenominazioneEsercizioComponent } from './denominazione-esercizio/denominazione-esercizio.component';
import { CodeEsercizioComponent } from './code-esercizio/code-esercizio.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ShellModule,
    IonicModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    CodaEsercizioComponent,
    TicketPrenotatoComponent,
    SlotPrenotabileComponent,
    DenominazioneEsercizioComponent,
    CodeEsercizioComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    CodaEsercizioComponent,
    TicketPrenotatoComponent,
    SlotPrenotabileComponent,
    DenominazioneEsercizioComponent,
    CodeEsercizioComponent
  ]
})
export class ComponentsModule {}
