import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


export class CodaEsercizioCommerciale {
  id: number; // id su database di Next
  durata_slot: number;
  color: string; // codice HTML del colore della coda
  nome: string;
  deleted: boolean; // non le cancelliamo veramente
  createdAt: string;
  updatedAt: string;
  name: string; // "banco carne"
  ultimo_chiamato: string; // serviamo il
  value: string; // probabilmente uguale a ultimo_chiamato
}

export class EsercizioCommerciale {
  id: string;
  denominazione: string;
  logoHref: string;
  logo: number;
  email: string;
  durata_slot_di_default: number;
  prenotazioniAttive: boolean;
  sito: string;
  telefono: string;
  url_path_relativo: string;
  coda: CodaEsercizioCommerciale[];
  coda_tmp: CodaEsercizioCommerciale[];
  logo_css_style: string;
}

@Injectable({
  providedIn: 'root'
})
export class EsercizioCommercialeService {

  constructor(
    public afs: AngularFirestore
  ) 
  {}

  getEserciziCommercialiList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.afs.collection<any>('users').snapshotChanges().
          subscribe(data => {
            if (typeof data !== 'undefined') {
              resolve(data);
            } else {
              reject('Impossilbile ottenere gli esercizi commerciali disponibili');
            }
            });
          }
    );
  }

  getEsercizioCommerciale(esercizioId: string): Observable<any> {
    return new Observable<any>((observer) => {
      const path: string = 'users/' + esercizioId;
      this.afs.doc<any>(path).valueChanges().
          subscribe(data => {
            if (typeof data !== 'undefined') {
              observer.next(data);
            } else {
              observer.error('Esercizio commerciale non trovato');
            }
            });
          }
    );
  }

  accettaPrenotazioniOnline(esercente: EsercizioCommerciale, attive: boolean): Promise<any> {
    esercente.prenotazioniAttive = attive;
    return this.afs.collection('users').doc(esercente.id).update({prenotazioniAttive: attive});    
  }

  setEmailEsercente(email: string): void {
    
  }


}

