import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { runInThisContext } from 'vm';
import { EsercizioCommerciale } from './esercizio-commerciale.service';
import { Observable } from 'rxjs';


export class CodaEsercizio {
  color: string; // codice colore HTML
  createdAt: string; // non che mi interessi
  updatedAt: string; // vedi sopra
  durata_slot: number; // in minuti
  deleted: boolean; // se vero, devo ignorarla
  id: number; // non è l'id di firebase, forse quello di next/online
  name: string; // nome visibile della coda, tipo "Farmaci"
  uid: string; // è lo stesso id assegnato in Firebase a questa coda. Serve a next/online
  ultimo_chiamato: string; // me lo comunica next/online
  value: number; // direi la stessa cosa di "id", ma ha un valore diverso
}

@Injectable({
  providedIn: 'root'
})
export class CodaEsercizioService {

  constructor(
    public afs: AngularFirestore
  ) { }

  getList(idEsercizio: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.afs.doc<any>('users/' + idEsercizio).collection<any>('coda')
        .snapshotChanges().
          subscribe(data => {
            if (typeof data !== 'undefined') {
              resolve(data);
            } else {
              reject('Impossilbile ottenere le code disponibili per ' + idEsercizio);
            }
          },
            error => {
              reject(error);
            });
          }
    );
  }

  get(esercizioId: string, codaId: string): Observable<any> {
    return new Observable<any>((observer) => {
      const path: string = 'users/' + esercizioId + '/coda/' + codaId;
      this.afs.doc<any>(path).valueChanges().
          subscribe(data => {
            if (typeof data !== 'undefined') {
              observer.next(data);
            } else {
              observer.error('Coda non trovata');
            }
            });
          }
    );
  }

}
