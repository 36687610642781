import { Injectable } from '@angular/core';
import { CodaEsercizio } from './coda-esercizio.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BusinessIdService {

  private codaAttuale: CodaEsercizio;
  public static businessIdParamName = 'businessId';
  public static businessAdminEmailParamname = 'businessOwnerEmail';  
  private lsprefix: string;

  constructor(
              private route: ActivatedRoute) {
                this.lsprefix = 'cloudnext_';
              }

  public set(value: string) {
    this.setParam(BusinessIdService.businessIdParamName, value);
  }

  public get(): string {
    let result: string = this.getParam(BusinessIdService.businessIdParamName);
    if (!result) {
      result = localStorage.getItem(BusinessIdService.businessIdParamName);
    } else {
      this.set(result);
    }
    return result;
  }

  public setEmail(value: string) {
    this.setParam(BusinessIdService.businessAdminEmailParamname, value);
  }

  public getEmail(): string {
    return this.getParam(BusinessIdService.businessAdminEmailParamname);
  }

  public setCodaAttuale(c: CodaEsercizio)
  {
    this.codaAttuale = c;
  }

  public getCodaAttuale(): CodaEsercizio 
  {
    return this.codaAttuale;
  }


  /**
   * Legge un parametro HTTP GET, lo salva in localStorage e lo restituisce.
   * Se il parametro manca nell'URL, restituisce quello precedentemente salvato 
   * in localStorage, o null se nemmeno in localStorage è mai stato salvato.
   * @param paramName il nome del parametro HTTP GET da leggere
   */
  getParam(paramName: string): string {
    // La teoria, almeno fino a dove l'ho capita, dice che per ottenere i parametri
    // HTTP GET basta prenderli da route.snapshot.queryParamMap.
    // Peccato che non funzioni, o almeno in questo codice non ritorni altro che null...
    let urlValue: string = this.route.snapshot.queryParamMap.get(paramName);

    // ... conseguenza: se non funziona (if (!urlValue) qui sotto) mando a quel paese 
    // Angular e Ionic e leggo i parametri con il
    // buon vecchio metodo JavaScript puro e semplice (URLSearchParams), che funziona 
    // sempre e che dimostra
    // che il problema è tutto nel fatto che sto usando (magari nel modo sbagliato)
    // Angular e Ionic, ma i parametri di fatto ci sono...
    if (!urlValue) {
      const urlParams = new URLSearchParams(window.location.search);
      urlValue = urlParams.get(paramName);

      // ... tranne quando sono su Internet Explorer, dove URLSearchParams non esiste.
      // In questo caso uso un accrochio trovato su StackOverflow e spero che funzioni,
      // ma non ho nessun IE su cui fare il test (né in realtà mi interessa testarlo,
      // diciamo che è un best effort, se funziona ben venga, se non funziona si fotta
      // l'utente che nel 2021+ usa ancora IE)
      if (!urlValue) {
        const name = paramName.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
        if (results) {
          if (!results[2]) {
            urlValue = '';
          } else {
            urlValue = decodeURIComponent(results[2].replace(/\+/g, ' '));        
          }
        }        
      }
    }
    if (urlValue) {
      localStorage.setItem(this.lsprefix + paramName, urlValue);
      return urlValue;
    } else {
      return localStorage.getItem(this.lsprefix + paramName);
    }
  }

  setParam(paramName: string, paramValue: string) {
    localStorage.setItem(this.lsprefix + paramName, paramValue);
  }

  initBusinessId(): string {
    return this.getParam(BusinessIdService.businessIdParamName);
  }

  public earlyInit() {
    this.initBusinessId();
  }

}

// cloudnext-app@teom.it
// No_matter_how_long_this_password_is_because_it_will_live_in_cleartext_into_the_app_anyway
