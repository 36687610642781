import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CodaEsercizio, CodaEsercizioService } from '../../api/coda-esercizio.service';
import { BusinessIdService } from '../../api/business-id.service';
import { TicketRef } from '../../api/prenotazione-coda.service';
import { OrariCodaService, OrarioCoda } from '../../api/orari-coda.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-code-esercizio',
  templateUrl: './code-esercizio.component.html',
  styleUrls: ['./code-esercizio.component.scss'],
})
export class CodeEsercizioComponent implements OnInit {

  public code: CodaEsercizio[] = Array<CodaEsercizio>();

  @Input() ticket_prenotati: TicketRef[];

  @Output() prenota = new EventEmitter();

  constructor(private codaEsercizioService: CodaEsercizioService,
              private businessIdService: BusinessIdService,
              private ocService: OrariCodaService) { }

  ngOnInit() {

    this.codaEsercizioService.getList(this.businessIdService.get())
      .then(
        (values) => {
          let myIndex = 0;
          values.forEach((doc, index) => {
            const tmpobj: CodaEsercizio = new CodaEsercizio();
            const srcobj = doc.payload.doc.data();
            Object.assign(tmpobj, srcobj);
            if (tmpobj.deleted === false) {
              tmpobj.id = doc.payload.doc.id;
              this.code.push(tmpobj);
              this.codaEsercizioService.get(this.businessIdService.get(), tmpobj.uid).subscribe(
                (value) => {
                  this.code[myIndex].ultimo_chiamato = value.ultimo_chiamato;
                  myIndex++;
                }
              );

            }
            /*
            const oo: Observable<any> = doc.valueChanges();
            oo.subscribe(observer => {
              
            });
            */
          });
        },
        (failedReason) => {
        }
      );
  }

  public notifica(coda: CodaEsercizio) {
    this.prenota.emit(coda);
  }

  public nonAncoraPrenotata(codaId: string) {
    let result = true;
    this.ticket_prenotati.forEach((item, index) => {
      if (item.idCoda === codaId) {
        result = false;
      }
    });
    return result;
  }

}
