import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CodaEsercizioService, CodaEsercizio } from '../../api/coda-esercizio.service';
import { BusinessIdService } from '../../api/business-id.service';
import { OrariCodaService } from '../../api/orari-coda.service';

@Component({
  selector: 'app-coda-esercizio',
  templateUrl: './coda-esercizio.component.html',
  styleUrls: ['./coda-esercizio.component.scss'],
})
export class CodaEsercizioComponent implements OnInit {

  @Input() idCoda: string;
  @Input() attiva: boolean;

  public gia_prenotato: boolean;
  public nome: string;
  public turnoattuale: string;

  public orariCaricati$: Observable<boolean>;

  @Output() prenota = new EventEmitter();

  constructor( private codaEsercizioService: CodaEsercizioService,
               private businessIdService: BusinessIdService,
               private ocService: OrariCodaService) {
  }

  ngOnInit() {
    const idEsercizio = this.businessIdService.get();
    const updaterObserver: Observable<any> = this.codaEsercizioService.get(idEsercizio, this.idCoda);
    this.updateData(updaterObserver);
    this.gia_prenotato = !this.attiva;

    this.orariCaricati$ = this.ocService.hasListForToday(idEsercizio, this.idCoda);
  }

  private updateData(updater: Observable<any>): void {
    updater.subscribe(
      (value) => {
        const coda: CodaEsercizio = new CodaEsercizio();
        Object.assign(coda, value);
        this.nome = coda.name.toUpperCase();
        this.turnoattuale = coda.ultimo_chiamato;
      },
      (failureReason) => {}
    );

  }

}
