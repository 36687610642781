import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { GiornoAttualeService } from './giorno-attuale.service';
import { Observer } from 'rxjs';
import { BusinessIdService } from './business-id.service';

export class OrarioDef {
  hour: number;
  minute: number;
  second: number;
}

export class OrarioCoda {
  id_giorno: string;
  ora_apertura: OrarioDef;
  ora_chiusura: OrarioDef;
}

@Injectable({
  providedIn: 'root'
})
export class OrariCodaService {

  constructor(private afs: AngularFirestore,
              private gaService: GiornoAttualeService) { }

  getList(idEsercizio: string, idCoda: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.afs.collection<any>('users/' + idEsercizio + '/coda/' + idCoda + '/orari/').snapshotChanges().
          subscribe(data => {
            if (typeof data != 'undefined') {
              resolve(data); 
            } else {
              reject('Impossilbile ottenere gli orari per la coda' + idCoda + ' dell\'esercizio ' + idEsercizio);
            }
          });
        }
    );
  }

  getTodayList(idEsercizio: string, idCoda: string): Observable<OrarioCoda[]> {
    const result = new Observable<OrarioCoda[]>((observer: Observer<OrarioCoda[]>) => {
      this.gaService.currentObservableDay().subscribe(
        (idxOggi: number) => {
          this.getList(idEsercizio, idCoda).then(
            (orariFB) => {
              const tempresult: OrarioCoda[] = Array<OrarioCoda>();
              orariFB.forEach((orarioFB) => {
                const orarioCoda: OrarioCoda = new OrarioCoda();
                const srcobj = orarioFB.payload.doc.data();
                Object.assign(orarioCoda, srcobj);
                if (parseInt(orarioCoda.id_giorno, 10) === idxOggi) {
                  tempresult.push(orarioCoda);
                }
              });
              observer.next(tempresult);
            });
          },
        (failureReason) => {
          observer.error(failureReason);
        }
      );
    });
    return result;
  }

  hasListForToday(idEsercizio: string, idCoda: string): Observable<boolean> {
    const result = new Observable<boolean>((observer: Observer<boolean>) => {
      this.getTodayList(idEsercizio, idCoda).subscribe((orari: OrarioCoda[]) => {
        observer.next(orari.length > 0);
      }); 
    });
    return result;
  }

  get(idEsercizio: string, idCoda: string, idOrario: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const path: string = 'users/' + idEsercizio + '/coda/' + idCoda + '/orari/' + idOrario;
      this.afs.doc<any>(path).valueChanges().
          subscribe(data => {
            if (typeof data != 'undefined') {
              resolve(data);
            } else {
              reject('Orario ' + idOrario + ' non trovato nella coda ' + idCoda + ' dell\'esercizio ' + idEsercizio);
            }
          });
        }
    );
  }

}
