import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { TicketRef, PrenotazioneCodaService } from './prenotazione-coda.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class DeviceIdService {

  constructor(private afs: AngularFirestore) {

  }

  get(): string {
    if (localStorage.getItem('cloudnext_deviceid') === null) {
      localStorage.setItem('cloudnext_deviceid', Guid.create().toString());
    }
    return localStorage.getItem('cloudnext_deviceid');
  }

  produceReload() {
    localStorage.setItem('cloudnext_reloadnextpage', '1');
  }

  consumeReload(): boolean {
    const result = localStorage.getItem('cloudnext_reloadnextpage');
    localStorage.setItem('cloudnext_reloadnextpage', '0');
    return result === '1';
  }

  addTicket(t: TicketRef): Promise<void> {
    const tKey = 'device_id/' + this.get() + '/prenotazioni/' + t.idPrenotazione + '/ticket/';
    return this.afs.collection(tKey).doc(t.getTicketId()).set({...t});
  }

  removeTicket(t: TicketRef): Promise<void> {
    const tKey = 'device_id/' + this.get() + '/prenotazioni/' + t.idPrenotazione + '/ticket/';
    return this.afs.collection(tKey).doc(t.getTicketId()).delete();
  }

  listTickets(giornoMillis: number): Promise<TicketRef[]> {
    const path = 
      'device_id/' + this.get() + 
      '/prenotazioni/' + PrenotazioneCodaService.getFlatDate(giornoMillis) + 
      '/ticket/';
    const result: Promise<TicketRef[]> = new Promise<TicketRef[]>((resolve, reject) =>
    {
      this.afs.collection(path).snapshotChanges().subscribe(data => {
        if (typeof data !== 'undefined') {
          const tr_data: TicketRef[] = new Array<TicketRef>();
          data.forEach((item, index) => {
            const tmpTr: TicketRef = new TicketRef();
            Object.assign(tmpTr, item.payload.doc.data());
            tr_data.push(tmpTr);
          });
          resolve(tr_data);
        } else {
          reject('Impossilbile ottenere i ticket');
        }
      });
    });
    return result;
  }
}
