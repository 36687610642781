<div class="coda-wrapper">
  <div >
    <div class="coda-nome">{{nome}}</div>
    <!-- <div class="coda-infoseparator"></div> --> 
  </div>
  <div class="coda-infowrapper">
    <div class="turno-numero">
      <div class="turno-attuale">{{turnoattuale}}</div>   
      <div class="coda-serviamo-il"><span class="button-acapo">Numero</span><span class="button-acapo">servito</span></div>    
    </div>
  <div *ngIf="orariCaricati$ | async as orarioCaricato">
    <button class="coda-prenota" [disabled]="gia_prenotato" (click)="prenota.emit()"><span class="button-acapo">Prenota</span><span class="button-acapo">un ticket</span></button>
  </div>
</div>
</div>
