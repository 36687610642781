// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBK9jZ8Z0jqz_ML5rJPkblEblIQoGTrU_s",
    authDomain: "next-teom.firebaseapp.com",
    databaseURL: "https://next-teom.firebaseio.com",
    projectId: "next-teom",
    storageBucket: "next-teom.appspot.com",
    messagingSenderId: "745440654925",
    appId: "1:745440654925:web:01d1780a85896cc751ce04",
    measurementId: "G-741ZJRB9PK"
  },
  appShellConfig: {
    debug: false,
    networkDelay: 1000
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
